import { YearPickerInputDemos, YearPickerDemos, MonthPickerDemos, MonthPickerInputDemos, DatePickerInputDemos, DatesProviderDemos, DateTimePickerDemos, DateInputDemos, ThemeDemos, OverlayDemos, ModalDemos, PinInputDemos, SpotlightDemos, CalendarDemos } from '@mantine/demos';
import * as React from 'react';
export default {
  YearPickerInputDemos,
  YearPickerDemos,
  MonthPickerDemos,
  MonthPickerInputDemos,
  DatePickerInputDemos,
  DatesProviderDemos,
  DateTimePickerDemos,
  DateInputDemos,
  ThemeDemos,
  OverlayDemos,
  ModalDemos,
  PinInputDemos,
  SpotlightDemos,
  CalendarDemos,
  React
};